const ROLE_SELLER_ADMIN = ['ROLE_VENDEDOR', 'ROLE_COBRANZA', 'ROLE_ADMIN', 'ROLE_ADMINISTRATOR_1', 'ROLE_ADMINISTRATOR_2'];
const ROLE_ADMIN = ['ROLE_ADMIN'];
const ROLE_COBRANZA = ['ROLE_COBRANZA', 'ROLE_ADMIN', 'ROLE_ADMINISTRATOR_1'];
const ROLE_ADMIN_LEVEL1 = ['ROLE_ADMIN', 'ROLE_ADMINISTRATOR_1'];
const ROLE_ADMIN_LEVEL2 = ['ROLE_ADMIN', 'ROLE_ADMINISTRATOR_2'];
export const SalesRouter = [
    {   path: '', component: () => import(/*webpackChunkName: "Dashboard"*/ '@/views/template/Dashboard.vue'),
        meta: { requiresAuth: true, roles: ROLE_SELLER_ADMIN }
    },
    // CLIENT MODULE
    {   path: 'clientes',            component: () => import(/* webpackChunkName: "ListaCliente"*/ "@/views/cliente/ListaCliente.vue"),
        meta: { requiresAuth: true, roles: ROLE_SELLER_ADMIN }
    },
    {   path: 'contactos/:idCliente', component: () => import( /* webpackChunkName: "ListaContacto"*/ "@/views/cliente/contacto/ListaContacto.vue"),
        name: 'ListaContacto', props: true, meta: { requiresAuth: true, roles: ROLE_SELLER_ADMIN }
    },
    {   path: 'compras/:clientId/:optCreditFilter', component: () => import( /* webpackChunkName: "ListaCompras"*/ "@/views/cliente/compras/ListaCompras.vue"),
        name: 'ListaCompras', props: ( route ) => {
            const clientId = Number(route.params.clientId);
            const optCreditFilter = Boolean(route.params.optCreditFilter);
            return isNaN(clientId) ? { clientId: null, optCreditFilter } : { clientId, optCreditFilter };
        },
        meta: { requiresAuth: true, roles: ROLE_SELLER_ADMIN }
    },
    {   path: "proveedores", component: () => import( /*webpackChunkName: "ListaProveedor"*/ "@/views/proveedor/ListaProveedor.vue"),
        name: "ListaProveedor", meta: { requiresAuth: true, roles: ROLE_SELLER_ADMIN }
    },    
    {   path: "entradas/:proveedorId?/:typeEntry?/:showInventory?", component: () => import( /*webpackChunkName: "ListaEntrada"*/ "@/views/entrada/ListaEntrada.vue"),
        name: "ListaEntrada", meta: { requiresAuth: true, roles: ROLE_SELLER_ADMIN },
        props: ( route ) => {
            const proveedorId = Number(route.params.proveedorId) || null;
            const typeEntry = String(route.params.typeEntry) || '';
            const showInventory = Boolean(route.params.showInventory) || false;
            return { proveedorId, typeEntry, showInventory };
        },
    },
    {
        path: "entradaLote/:entradaDetalleId", component: () => import( /*webpackChunkName: "ListaEntradaDetalleLote"*/ "@/views/entrada/entradaDetalleLote/ListaEntradaDetalleLote.vue"),
        name: "ListaEntradaDetalleLote", props: true, meta: { requiresAuth: true, roles: ROLE_SELLER_ADMIN }
    },
    {
        path: "cashRegister", component: () => import( /*webpackChunkName: "CashRegisterForm"*/ "@/views/cashRegister/CashRegister.vue"),
        name: "CashRegister", meta: { requiresAuth: true, roles: ROLE_SELLER_ADMIN },
        props: true
    },
    {   path: "ventas", component: () => import(/*webpackChunkName: "ListaVenta"*/ "@/views/venta/ListaVenta.vue"),
        name: "ListaVenta", meta: { requiresAuth: true, roles: ROLE_SELLER_ADMIN }
    },
    {   path: "pagos", component: () => import(/*webpackChunkName: "ListaVenta"*/ "@/views/venta/pago/ListaPago.vue"),
        name: "ListaPago", meta: { requiresAuth: true, roles: ROLE_SELLER_ADMIN }
    },
    {   path: "pagosProveedores/:proveedorId?", component: () => import(/*webpackChunkName: "ListaVenta"*/ "@/views/proveedor/pago/ListaPago.vue"),
        name: "ListaPagoProveedor", meta: { requiresAuth: true, roles: ROLE_SELLER_ADMIN },
        props: true,
    },
    {
        path: 'transactionCategory', component: () => import(/*webpackChunkName: "TransactionCategory"*/ '@/views/transactions/transactionCategory/ListaTransactionCategory.vue'),
        name: 'TransactionCategory', meta: { requiresAuth: true, roles: ROLE_ADMIN_LEVEL1 }
    },
    {
        path: 'transactions', component: () => import(/*webpackChunkName: "Transactions"*/ '@/views/transactions/ListaTransaction.vue'),
        name: 'Transactions', meta: { requiresAuth: true, roles: ROLE_ADMIN_LEVEL1 }
    }
]
export const AdminRouter = [
    ...SalesRouter,
    {   path: "profile", component: () => import(/*webpackChunkName: "Profile"*/ "@/views/auth/Profile.vue"),
        meta: { requiresAuth: true, roles: ROLE_ADMIN }
    },
    // COMPANY MODULE
    {   path: "empresas",   component: () => import(/*webpackChunkName: "ListaEmpresa"*/ "@/views/empresa/ListaEmpresa.vue"),
        name: "ListaEmpresa", meta: { requiresAuth: true, roles: ROLE_ADMIN }
    },
    {   path: "listaRaya",   component: () => import(/*webpackChunkName: "ListaRaya"*/ "@/views/raya/ListaRaya.vue"),
        name: "ListaRayas", meta: { requiresAuth: true, roles: ROLE_ADMIN_LEVEL1 }
    },
    {   path: "sucursales", component: () => import(/*webpackChunkName: "ListaSucursal"*/ "@/views/catalogos/sucursal/ListaSucursal.vue"),
        name: "ListaSucursal", meta: { requiresAuth: true, roles: ROLE_ADMIN }
    },
    // USERS MODULE
    {   path: "usuarios", component: () => import(/*webpackChunkName: "ListaUsuario"*/ "@/views/usuario/ListaUsuario.vue"),
        name: "Usuario", meta: { requiresAuth: true, roles: ROLE_ADMIN }
    },
    {   path: 'empleados', component: () => import(/*webpackChunkName: "ListaEmpleado"*/ "@/views/empleado/ListaEmpleado.vue"),
        name: 'ListaEmpleado', meta: { requiresAuth: true, roles: ROLE_ADMIN_LEVEL1 }
    },
    {   path: 'puestos', component: () => import(/*webpackChunkName: "ListaPuesto"*/ "@/views/catalogos/puesto/ListaPuesto.vue"),
        name: 'ListaPuesto', meta: { requiresAuth: true, roles: ROLE_ADMIN_LEVEL1 }
    },
    {   path: 'departamentos', component: () => import(/*webpackChunkName: "ListaDepartamento"*/ "@/views/catalogos/departamento/ListaDepartamento.vue"),
        name: 'ListaDepartamento', meta: { requiresAuth: true, roles: ROLE_ADMIN_LEVEL1 }
    },
    // PRODUCT MODULE
    {   path: 'unidad', component: () => import(/*webpackChunkName: "ListaUnidad"*/ '@/views/catalogos/unidad/ListaUnidad.vue'),
        name: 'ListaUnidad', meta: { requiresAuth: true, roles: ROLE_ADMIN }
    },
    {   path: "productos", component: () => import(/*webpackChunkName: "ListaProducto"*/"@/views/producto/ListaProducto.vue"),
        name: "ListaProducto", meta: { requiresAuth: true, roles: ROLE_ADMIN_LEVEL2 }
    },
    {   path: 'listaPrecio', component: () => import(/*webpackChunkName: "ListaPrecio"*/ '@/views/producto/listaPrecio/ListaPrecio.vue'),
        name: 'ListaPrecio', meta: { requiresAuth: true, roles: ROLE_ADMIN_LEVEL2 }
    },
    {   path: "listaTipoEntrada", component: () => import( /*webpackChunkName: "ListaTipoEntrada"*/ "@/views/entrada/tipoEntrada/ListaTipoEntrada.vue"),
        name: "ListaTipoEntrada", meta: { requiresAuth: true, roles: ROLE_ADMIN_LEVEL1 }
    },
 
    {   path: 'almacen', component: () => import(/*webpackChunkName: "ListaAlmacen"*/ '@/views/catalogos/almacen/ListaAlmacen.vue'),
        name: 'ListaAlmacen', meta: { requiresAuth: true, roles: ROLE_ADMIN_LEVEL1 }
    },
    // REPORTS MODULE
    {   path: "calendario", component: () => import(/*webpackChunkName: "CalendarioAsistencia"*/ '@/views/asistenciaPersonal/calendarioAsistencia.vue'),
        name: 'CalendarioAsistencia', meta: { requiresAuth: true, roles: ROLE_ADMIN_LEVEL2 }
    },
    {   path: 'asistencias', component: () => import(/*webpackChunkName: "ListaAsistencia"*/ '@/views/asistenciaPersonal/ListaAsistencia.vue'),
        name: 'ListaAsistencia', meta: { requiresAuth: true, roles: ROLE_ADMIN_LEVEL1 }
    },
    {   path: 'deducciones', component: () => import(/*webpackChunkName: "ListaAsistencia"*/ '@/views/deduccion/ListaDeduccion.vue'),
        name: 'ListaDeduccion', meta: { requiresAuth: true, roles: ROLE_COBRANZA }
    },
    {   path: "reportes", component: () => import(/*webpackChunkName: "Reportes"*/ '@/views/reportes/ListaReporte.vue'),
        name: 'Reportes', meta: { requiresAuth: true, roles: ROLE_ADMIN_LEVEL2  }
    },
]